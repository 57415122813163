import React, { Component } from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

import style from "./portfolio.module.css"

const loadingGif = require(`../assets/loading-large.gif`)

class Portfolio extends Component {
	state = {
		isMobile: false
	}

	componentDidMount() {
		if (window.innerWidth < 650) {
			this.setState({ isMobile: true })
		} else {
			this.setState({ isMobile: false })
		}
	}

	importAll = data => {
		let images = {}
		data.keys().map((item, index) => {
			return (images[item.replace("./", "")] = data(item))
		})
		return images
	}

	render() {
		const getFiltered = array => {
			return Object.fromEntries(
				Object.entries(array).filter(([key, value]) => {
					return value.includes("thumb")
				})
			)
		}

		const images = this.importAll(
			require.context(
				"../assets/covers/art/compressed/",
				false,
				/\.(png|jpe?g|svg)$/
			)
		)

		const names = [
			"Gabriella",
			"Angelina",
			"Gabriella",
			"Darina",
			"Georgia",
			"Darina",
			"Heidi",
			"Gabriella",
			"Kate",
			"Kayla",
			"Heidi",
			"Lucia",
			"VikTory",
			"Lucia",
			"Kate",
			"Maria",
			"Noelia"
		]

		const filteredImages = getFiltered(images)

		return (
			<>
				<SEO
					title="Art & Fashion Photography"
					description="Artistic, Fashion, Editorial Photography Portfolio by Andreas Karpasitis"
					keywords="Karpasitis, Andreas, Photography, Photographer, Photo, Fotografia, Barcelona, Fashion, Editorial, Art, Artistic"
					url="https://www.karpasitis.net/art"
				/>
				<div className={style.container}>
					<header>
						<h1 className={style.titleHeader}>
							Art & Fashion Photography
						</h1>
					</header>
					<div className={style.photos} id="photos">
						{Object.keys(filteredImages).map((image, index) => {
							const imageIndex = index + 1
							const imageNumber =
								imageIndex < 10 ? "0" + imageIndex : imageIndex
							const imageSrc = require(`../assets/covers/art/compressed/${imageNumber}-thumb.jpg`)

							return (
								<Link key={image} to={`/art/${imageNumber}`}>
									<div
										key={`${image}`}
										className={style.link}
									>
                    <img
                      className="lazyload"
											key={`${image}-img`}
											alt={names[index]}
											src={loadingGif}
											data-src={imageSrc}
											data-sizes="auto"
										/>
										<div className={style.title}>
											{names[index]}
										</div>
										{/* {!this.state.isMobile && (
											<div className={style.title}>
												{names[index]}
											</div>
										)} */}
									</div>
								</Link>
							)
						})}
					</div>
				</div>
			</>
		)
	}
}

export default Portfolio
