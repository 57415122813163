import React, { Component } from "react"
import Portfolio from "./portfolio"
import Gallery from "../components/organisms/Gallery"
import Layout from "./layout.js"

class Art extends Component {
  render() {
    const { id = null } = this.props
    return <Layout>{id ? <Gallery id={id} /> : <Portfolio />}</Layout>
  }
}

export default Art
