import React, { Component } from "react"
import _ from "lodash"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import SEO from "../../seo"

import style from "./index.module.css"

const loadingGif = require(`../../../assets/loading-large.gif`)

const names = [
	"Gabriella",
	"Angelina",
	"Gabriella",
	"Darina",
	"Georgia",
	"Darina",
	"Heidi",
	"Gabriella",
	"Kate",
	"Kayla",
	"Heidi",
	"Lucia",
	"VikTory",
	"Lucia",
	"Kate",
	"Maria",
	"Noelia"
]

class Gallery extends Component {
	state = {
		openModal: false,
		imageNumber: 0,
		modalImage: ""
	}

	importAll = data => {
		let images = {}
		data.keys().map((item, index) => {
			return (images[item.replace("./", "")] = data(item))
		})
		return images
	}

	getImages = gallery => {
		switch (gallery) {
			case "01":
				return this.importAll(
					require.context(
						"../../../assets/art/01/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "02":
				return this.importAll(
					require.context(
						"../../../assets/art/02/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "03":
				return this.importAll(
					require.context(
						"../../../assets/art/03/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "04":
				return this.importAll(
					require.context(
						"../../../assets/art/04/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "05":
				return this.importAll(
					require.context(
						"../../../assets/art/05/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "06":
				return this.importAll(
					require.context(
						"../../../assets/art/06/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "07":
				return this.importAll(
					require.context(
						"../../../assets/art/07/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "08":
				return this.importAll(
					require.context(
						"../../../assets/art/08/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "09":
				return this.importAll(
					require.context(
						"../../../assets/art/09/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "10":
				return this.importAll(
					require.context(
						"../../../assets/art/10/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "11":
				return this.importAll(
					require.context(
						"../../../assets/art/11/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "12":
				return this.importAll(
					require.context(
						"../../../assets/art/12/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "13":
				return this.importAll(
					require.context(
						"../../../assets/art/13/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "14":
				return this.importAll(
					require.context(
						"../../../assets/art/14/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "15":
				return this.importAll(
					require.context(
						"../../../assets/art/15/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "16":
				return this.importAll(
					require.context(
						"../../../assets/art/16/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			case "17":
				return this.importAll(
					require.context(
						"../../../assets/art/17/compressed/",
						false,
						/\.(png|jpe?g|svg)$/
					)
				)
			default:
				break
		}
	}

	render() {
		const getFiltered = array => {
			return Object.fromEntries(
				Object.entries(array).filter(([key, value]) => {
					return value.includes("thumb")
				})
			)
		}

		const gallery = this.props.id
		const images = this.getImages(gallery)
		const numberOfImages = _.size(images) / 2
		const filteredImages = getFiltered(images)

		return (
			<div className={style.container}>
				<SEO
					title={names[Number(gallery) - 1]}
					description="Artistic, Fashion, Editorial Photography Portfolio by Andreas Karpasitis"
					keywords="Karpasitis, Andreas, Photography, Photographer, Photo, Fotografia, Barcelona, Fashion, Editorial, Art, Artistic"
					url={`https://www.karpasitis.net/art/${gallery}`}
				/>
				<h1 className={style.title}>{names[Number(gallery) - 1]}</h1>
				<div
					className={
						numberOfImages <= 3 ? style.photosSpecial : style.photos
					}
					id="photos"
				>
					{Object.keys(filteredImages).map((image, index) => {
						const imageSource = require(`../../../assets/art/${gallery}/image${index +
							1}.jpg`)
						return (
							<div key={image} className={style.link}>
                <img
                  className="lazyload"
									src={loadingGif}
									data-src={imageSource}
									data-size="auto"
									key={`${image}-img`}
									alt="another"
								/>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

export default Gallery
